import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import SiteLinks from "../components/SiteLinks"
import { Avatar, Input, List, Row, Col } from 'antd';
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader";
deckDeckGoHighlightElement();

export const frontmatter = {
  layoutType: `page`,
  path: `/notebooks/`,
}

const Search = Input.Search;

class Notebooks extends React.Component {

  notebookLink = (item) => {
    // console.log(item);
    return item.github ?
      <a href={item.github}>NOTEBOOK</a> :
      "NOTEBOOK"
  }

  render() {
    // console.log(this.props.data.allNotebooksJson.edges);
    let nodes = this.props.data.allNotebooksJson.edges.map(item => {
      return item.node
    })
    // console.log('nodes', nodes);
    return (
      <div className="container">
        <h1>Notebooks</h1>
        <p>
          Collection of Python and R notebooks
        </p>
        <Row gutter={10}>
          <Col span={10}>
            <Search
              placeholder="input search text"
              onSearch={value => console.log(value)}
              // style={{ width: 200 }}
            />
          </Col>
          </Row>
          <Row>
          <Col>
            <List
              itemLayout="horizontal"
              dataSource={nodes}
              renderItem={item => (
                <List.Item actions={[<a href={item.html}>HTML</a>, this.notebookLink(item)]}>
                  <List.Item.Meta                    
                    title={item.name}
                    description={item.title}
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

export default Notebooks

export const pageQuery = graphql`
    query notebooksquery {
      allNotebooksJson {
        edges {
          node {
            name
            title
            github
            html
            language
            tags
          }
        }
      }
    }
  `
